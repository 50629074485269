export const hero = {
  title: `Real users rate SpotOn as the best POS system`,
  subtext:
    "According to real user reviews submitted to Capterra, SpotOn is the top-rated point-of-sale (POS) for Restaurants, Bars, Retail, and Small Business. SpotOn received the highest marks among its competition across all categories measured, including functionality, ease of use, customer support, and value for money.",
  ctaPrimary: {
    title: "Learn more",
    target: "/restaurant-pos/capterra-competive-comparison/demo",
  },
  imageName: "capterra-hero.png",
};

export const links = [
  {
    label: "Best POS",
    href: "#best-pos",
  },
  {
    label: "Best Restaurant POS",
    href: "#best-restaurant-pos",
  },
  {
    label: "Best Bar POS",
    href: "#best-bar-pos",
  },
  {
    label: "Best Retail POS",
    href: "#best-retail-pos",
  },
  {
    label: "Best SMB POS",
    href: "#best-smb-pos",
  },
];

export const bestPOS = {
  title: "The best point-of-sale system overall",
  subtext:
    "The results are in. When POS users took to Capterra to rate point-of-sale companies, SpotOn came out ahead of the competition in every category. And when it came to value for money and customer support, SpotOn was the only company to score above the 90-point benchmark.",
  cta: {
    title: "See the results",
    target:
      "https://datainsights.capterra.com/p/point-of-sale/226928/spoton/references?c=&c=&c=&sc=6320065",
  },
  imageName: "capterra-best-pos.png",
};

export const testimonialsCapterra = {
  title: "See what business owners are saying about SpotOn",
  subTitle:
    "With an average 4.7-star rating, SpotOn is recognized by users for saving them money, being easy to use, and providing unmatched customer support.",
  reviews: [
    {
      quote:
        "Our bartenders love the platform for its ease of use, new bartenders coming from other Point of Sales systems rave about Spoton.",
      reviewDate: "May 16, 2022",
      reviewer: "Tony B",
      reviewerBio: "Director of Operations",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Easy to understand and use, The customer service while training the employees was exceptionally awesome!!",
      reviewDate: "March 25, 2022",
      reviewer: "Jeremy L",
      reviewerBio: "Owner",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote: "Love the qr code, ease of use, customer services is very helpful",
      reviewDate: "Feb 17, 2022",
      reviewer: "Susan H",
      reviewerBio: "Owner",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn is #1 choice for our POS. Excellent service and program, if they don't offer it, just ask and they will get it. ",
      reviewDate: "Aug 25, 2021",
      reviewer: "Teri F",
      reviewerBio: "Owner",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has been terrific right from the beginning. I've used multiple POS and this is the best thus far",
      reviewDate: "Aug 20, 2021",
      reviewer: "Daniel R",
      reviewerBio: "General Manager",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const features = {
  title: "Top POS systems across multiple categories",
  items: [
    {
      id: "best-restaurant-pos",
      className: "",
      title: "Best restaurant POS system",
      subtext:
        "Restaurant operators awarded SpotOn the highest marks for restaurant point-of-sale across every quality measured, beating out Square, Clover, and Toast.",
      imageName: "capterra-best-restaurant-pos.png",
      cta: {
        title: "See the results",
        target:
          "https://spoton.com/blog/real-users-prefer-spoton-vs-toast-competitors",
      },
    },
    {
      id: "best-bar-pos",
      className: "bg-primary-50",
      title: "Best bar POS system",
      subtext:
        "What are some of the features that give SpotOn the top spot according to bar owners and operators? A clear but comprehensive interface, ease of use when training new bartenders, and affordable pricing.",
      imageName: "capterra-best-bar-pos.png",
      cta: {
        title: "See the results",
        target:
          "https://spoton.com/blog/operators-rate-spoton-bar-POS-above-contenders",
      },
    },
    {
      id: "best-retail-pos",
      className: "",
      title: "Best POS system for retail",
      subtext:
        "With its ability to unify online and in-shop inventory under one platform, SpotOn Retail edged out other retail POS systems, including Square for Retail, Lightspeed Retail, and Clover, according to real user reviews.",
      imageName: "capterra-best-retail-pos.png",
      cta: {
        title: "See the results",
        target:
          "https://spoton.com/blog/retail-pos-spoton-vs-clover-competitors/",
      },
    },
    {
      id: "best-smb-pos",
      className: "bg-primary-30",
      title: "Best POS for small business",
      subtext:
        "Small and midsize business owners give SpotOn technology top reviews for its reliability, ease of use, and 24/7/365 responsive customer support.",
      imageName: "capterra-best-smb-pos.png",
      cta: {
        title: "See the results",
        target:
          "https://spoton.com/blog/small-businesses-prefer-spoton-vs-square-competitors/",
      },
    },
  ],
};

export const behindTheRatings = {
  title: "The method behind the ratings",
  subtext:
    "Capterra’s Reviews Insight Competitive Comparison is based on end-user reviews. Average ratings and review counts include published reviews within a 12-month trailing year of the publication date. Considered reviews were limited to reviewers from North America. Ratings are collected on a 1-to-5 basis and scaled to 20 to 100 for comparative purposes. ",
};

export const quote = {
  text: "“We build our technology and run our business on empathy. We put our clients at the center of everything we do, asking, ‘Will this make life easier? Will it drive revenue? Will it streamline admin? Will it solve a problem when they need it most?’ It’s all about flexibility and working the way they work.”",
  personName: "Kevin Bryla",
  personPosition: "Head of Customer Experience at SpotOn.",
  imageName: "kevin-bryla.png",
};

export const footer = {
  title: "Learn more about the POS everyone is talking about",
  subtext:
    "Get in touch with one of our team members and see if SpotOn POS solutions are a good fit for your business.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/restaurant-pos/capterra-competive-comparison/demo",
  },
};
