import React from "react";
import { motion } from "framer-motion";
import { useHash } from "@mantine/hooks";
import { isMobileOnly } from "react-device-detect";
import useStartMotion from "../../../hooks/use-start-motion";
import { slideBottomVariants } from "../../../utils/animations";

import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Section from "../../../components-v2/Base/Section";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import CtaPrimary from "../../../components-v2/Base/CtaPrimary";
import HeroImage from "../../../components/ImageQuerys/HeroImages";
import Image from "../../../components/ImageQuerys/LargeFeaturesImages";
import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";

import footerSvg from "../../../images/svg/capterra-footer-vector.svg";
import kevinBg from "../../../images/svg/vector-21.svg";
import heroBg from "../../../images/hero-images/capterra-hero.png";

import {
  hero,
  links,
  bestPOS,
  features,
  behindTheRatings,
  testimonialsCapterra,
  quote,
  footer,
} from "../../../data/capterra";

const CapterraLP = () => {
  const { ref, controls } = useStartMotion();
  const [hash, setHash] = useHash();

  return (
    <Layout
      className="non-fixed-width a-ec"
      transparentHeader
      isLanding
      useNewLPHeader
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="Capterra Best POS System Competitive Comparisons | SpotOn"
        description="See how SpotOn stacks up against Square, Clover, Toast, Epos Now, and Lightspeed when real users review their POS systems on Capterra—the most comprehensive software directory on the web."
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/restaurant-pos/capterra-competive-comparison/"
        productSchemaSR
      />

      <Section className="bg-black pt-28 lg:pt-40" fullWidth>
        <Heading className="text-white text-center mx-auto" withBlueSymbol>
          {hero.title}
        </Heading>

        <p className="text-black-400 text-[20px] mt-6 lg:mt-8 text-center mx-auto max-w-[1000px]">
          {hero.subtext}
        </p>

        <CtaPrimary className="mx-auto mt-6" {...hero.ctaPrimary} />

        <HeroImage
          className="mt-10 mx-auto max-w-[820px]"
          imageName={hero.imageName}
        />
      </Section>

      <Section
        className="mini-nav-section mx-auto"
        fullWidth
        style={{
          boxShadow:
            "0px 15px 80px rgba(24, 36, 65, 0.07), 0px 6.26664px 33.4221px rgba(24, 36, 65, 0.0503198), 0px 3.35045px 17.869px rgba(24, 36, 65, 0.0417275), 0px 1.87823px 10.0172px rgba(24, 36, 65, 0.035), 0px 0.997515px 5.32008px rgba(24, 36, 65, 0.0282725), 0px 0.415088px 2.21381px rgba(24, 36, 65, 0.0196802)",
        }}
        animations={false}
      >
        <div
          className={`
          flex flex-col md:flex-row gap-x-8 justify-center
          mb-[-20px] lg:mb-[-40px] gap-y-6
        `}
        >
          {links.map((link, idx) => (
            <div
              key={link.label}
              className={`
              l-item md:pb-6
              ${(idx === 0 && !hash) || hash === link.href ? "active" : ""}
            `}
            >
              <a
                href={link.href}
                className="text-black text-p"
                style={{ fontWeight: 500 }}
              >
                {link.label}
              </a>
            </div>
          ))}
        </div>
      </Section>

      <Section className="mt-20 lg:mt-24 2xl:mt-32" id="best-pos">
        <Heading
          className="lg:max-w-[595px] text-center lg:text-left"
          withBlueSymbol
          level={2}
          injectionType={1}
        >
          {bestPOS.title}
        </Heading>

        <div
          className={`
          flex flex-col lg:flex-row justify-between items-center
          gap-10 mt-10 lg:mt-[60px]
        `}
        >
          <div className="w-full h-auto max-w-[630px]">
            <Image imageName={bestPOS.imageName} />
          </div>

          <div className="lg:max-w-[400px]">
            <p className="text-black-300 text-[20px] text-center lg:text-left">
              {bestPOS.subtext}
            </p>

            <CtaPrimary
              className="mt-10 mx-auto lg:mx-0"
              {...bestPOS.cta}
              newTab
            />
          </div>
        </div>
      </Section>

      <Section fullWidth fullWidthCarousel className="mt-20 lg:mt-24 2xl:mt-32">
        <div className="max-w-[1500px] mx-auto">
          <TestmonialReviews sectionData={testimonialsCapterra} />
          <PartnerLogos />
        </div>
      </Section>

      <Section className="mt-20 lg:mt-24 2xl:mt-32">
        <Heading
          level={2}
          injectionType={1}
          withBlueSymbol
          className="max-w-[760px] xl:ml-10"
        >
          {features.title}
        </Heading>

        {/* {features.items.map((item, idx) => ( */}
        {/*  <div */}
        {/*    className={` */}
        {/*      flex flex-col items-center justify-between  gap-10 */}
        {/*      w-full max-w-[1360px] mx-auto */}
        {/*      px-4 md:px-10 py-20 lg:py-[120px] xl:px-20 */}
        {/*      ${idx % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"} */}
        {/*      rounded-[16px] */}
        {/*      ${item.className} */}
        {/*    `} */}
        {/*    id={item.id} */}
        {/*    key={item.id} */}
        {/*  > */}
        {/*    <div className="w-full h-auto max-w-[600px]"> */}
        {/*      <Image imageName={item.imageName} /> */}
        {/*    </div> */}

        {/*    <div className="lg:max-w-[440px]"> */}
        {/*      <Heading level={2} injectionType={2} withBlueSymbol className=""> */}
        {/*        {item.title} */}
        {/*      </Heading> */}

        {/*      <p className="mt-8 text-black-200 text-p">{item.subtext}</p> */}

        {/*      <CtaPrimary className="" {...item.cta} newTab /> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* ))} */}
      </Section>

      {features.items.map((item, idx) => (
        <Section fullWidth fullWidthCarousel className="">
          <div
            className={` 
              flex flex-col items-center justify-between  gap-10 
              w-full max-w-[1360px] mx-auto 
              px-4 md:px-10 py-20 lg:py-[120px] xl:px-20 
              ${idx % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"} 
              xl:rounded-[16px] 
              ${item.className} 
            `}
            id={item.id}
            key={item.id}
          >
            <div className="w-full h-auto max-w-[600px]">
              <Image imageName={item.imageName} />
            </div>

            <div className="lg:max-w-[440px]">
              <Heading level={2} injectionType={2} withBlueSymbol className="">
                {item.title}
              </Heading>

              <p className="mt-8 text-black-200 text-p">{item.subtext}</p>

              <CtaPrimary className="" {...item.cta} newTab />
            </div>
          </div>
        </Section>
      ))}

      <Section className="mt-20 lg:mt-24 2xl:mt-32">
        <div className="flex flex-col lg:flex-row justify-between  lg:items-center gap-10 xl:px-20">
          <Heading
            level={2}
            injectionType={1}
            className="max-w-[400px] lg:max-w-[500px] "
            withBlueSymbol
          >
            {behindTheRatings.title}
          </Heading>

          <p
            className={`
            text-black-200 text-p
            lg:max-w-[400px] xl:max-w-[450px]
          `}
          >
            {behindTheRatings.subtext}
          </p>
        </div>
      </Section>

      <Section className="mt-20 lg:mt-24 2xl:mt-32">
        <div
          className={`
            flex flex-col md:flex-row justify-between md:items-stretch
            gap-8 xl:gap-16
          `}
        >
          <div
            className={`
            bg-primary-50 rounded-[16px]
            px-4 py-10
            md:px-8 md:py-[160px]
            lg:px-8 lg:py-[160px]
            xl:px-[60px] xl:py-[160px]
          `}
          >
            <p className="text-black text-[18px] xl:text-[24px]">
              {quote.text}
            </p>
            <div className="text-primary text-[18px] xl:text-[24px] mt-10">
              {quote.personName}
            </div>
            <div className="text-black-200 text-[14px]">
              {quote.personPosition}
            </div>
          </div>

          <div
            className={`hidden lg:flex flex-col items-end justify-end
            bg-black-800 rounded-[16px] w-full min-w-[200px] lg:min-w-[300px] xl:min-w-[385px] xl:max-w-[385px]
            relative overflow-hidden
          `}
          >
            <img
              alt="background"
              src={kevinBg}
              className="absolute top-0 right-0"
            />
            <Image
              // className="max-w-[300px] w-full absolute bottom-0 right-0 xl:right-[20px] quote-image"
              className="max-w-[300px] w-full xl:mr-[20px] quote-image"
              imageName={quote.imageName}
              // style={{ position: "absolute !important" }}
            />
          </div>
        </div>
      </Section>

      <Section className="my-20 lg:my-24 2xl:my-32 overflow-hidden">
        <motion.div
          className={`relative bg-black rounded-[32px]
          px-10 md:px-20 2xl:px-[126px]
          py-20 md:py-40 lg:py-[256px]
          flex flex-col xl:flex-row justify-between xl:items-center
          gap-x-10 lg:gap-x-[126px]
          overflow-hidden
        `}
          ref={ref}
          animate={controls}
          initial={isMobileOnly ? "visible" : "hidden"}
          variants={slideBottomVariants}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          <img
            alt="spoton"
            src={footerSvg}
            className="hidden lg:block absolute bottom-0 left-0"
          />

          <Heading
            level={2}
            injectionType={1}
            withBlueSymbol
            className="text-white md:max-w-[525px] w-full relative"
          >
            {footer.title}
          </Heading>

          <div className="relative">
            <p className="text-black-700 text-p relative">{footer.subtext}</p>

            <CtaPrimary className="mt-8 relative" {...footer.ctaPrimary} />
          </div>
        </motion.div>

        <div
          className={`
            text-white text-[14px] -rotate-90 absolute 
            hidden md:block
            left-0 bottom-20
            md:left-[65px] md:bottom-20
            lg:left-40 lg:bottom-32
            xl:left-32
          `}
        >
          ONE PARTNER.
        </div>
      </Section>

      <style jsx="true">{`
        .l-item.active {
          border-bottom: 3px solid #1769ff;
        }
      `}</style>
    </Layout>
  );
};

export default CapterraLP;
